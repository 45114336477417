.home-wrapper{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/Banner\ 1.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
}
.home-hero{
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
} 
.hero-text{
    text-align: center;
    color: white;
}
.hero-text h1{
    font-weight: 800;
    font-size: 55px;
    margin-bottom: 0px;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: .5px white;
    background: url(../img/colorbg.png);
    -webkit-background-clip: text;
    background-position: 0 0;
    animation: back 20s linear infinite;
    /* background: -webkit-linear-gradient(45deg, #ff0000, #132F54, #e600ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

@keyframes back {
    100%{
        background-position: 10000px 0;
    }
}
.hero-text h3{
    margin-top: 0px;
    font-size: 55px;
    font-family: "Dancing Script", cursive;
    font-weight: 400;
}
.location-wrapper{
    width: 100%;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
    border-bottom: 1px solid rgb(135, 135, 135);
}
.main-location{
    width: 70%;
    height: 350px;
    /* background: #132F54; */
    background: #000000;
    /* box-shadow: 0 4px 8px 0 rgba(136, 136, 136, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* border-bottom: 2px solid white; */
    /* border-radius: 5px; */
    padding: 30px;
    background-size: cover;
    background-position: bottom;
    color: white;
}
.main-location h2{
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 28px;
    position: relative;
}
.main-location h2::after{
    content: '';
    height: 2px;
    background-color: white;
    width: 20%;
    position: absolute;
    bottom: 15px;
    left: 215px;
}
.loc-box{
    display: flex;
    gap: 40px;
}
.img-cont img{
    width: 100%;
}
.sof-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/stars.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    transition: all .5s ease;
}
.sof-hero{
    width: 70%;
    display: flex;
    padding: 60px 0px 40px 0px;
} 
.sof-text{
    color: white;
}
.sof-text h2{
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 28px;
    position: relative;
}
.sof-text h2::after{
    content: '';
    height: 2px;
    background-color: white;
    width: 20%;
    position: absolute;
    bottom: 15px;
    left: 225px;
}
.sof-text p{
    margin-top: 0px;
}
p{
    line-height: 28px;
}
.sof-wrap-card{
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    gap: 30px;
}
.hover-open{
    /* border: 1px solid white; */
    color: rgb(255, 255, 255);
    border-radius: 8px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
}
.hover-open-title{
    border-bottom: 1px solid rgb(255, 255, 255);
    padding: 20px 40px;
    margin: 0px;
}
.hover-open p{
    padding: 20px 40px;
}
.hover-wrap{
    display: flex;
    gap: 30px;
}
.other-ministry{
    color: white;
}
.other-ministry button{
    margin-top: 20px;
    padding: 10px 15px;
    font-weight: 700;
    font-size: 15px;
    border-radius: 4px;
    outline: none;
    border: none;
    color: #001987;
    cursor: pointer;
}
.vision-wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../img/bg\ 1.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.vision-container{
    width: 70%;
    text-align: center;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
    color: rgb(0, 0, 0);
    margin: 50px 0px;
    padding: 50px 0px;
    border-radius: 10px;
}
.vision-container h2{
    font-size: 28px;
    margin-top: 0px;
}
.vision-container p{
    padding: 0px 50px;
    font-size: 20px;
}
.contact-wrap{
    width: 100%;
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    border-bottom: 1px solid rgb(53, 53, 53);
}
.contact-container{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;
}
.contact-container h2{
    color: white;
    position: relative;
}
.contact-container h2::after{
    content: '';
    height: 2px;
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 115px;
}
.cards-cont{
    display: flex;
    gap: 20px;
    width: max-content;
    align-items: center;
    color: white;
    border: 1px solid white;
    padding-right: 20px;
    border-radius: 5px;
    overflow: hidden;
}
.contact-cards{
    display: flex;
    gap: 30px;
}

.cards-cont h3, p{
    margin: 0px;
}
.img-cn{
    margin: 0px;
    border-right: 1px solid white;
    height: 70px;
}
.contact-container img {
    width: 70px;
}
.copyrights{
    text-align: center;
    background: #000000;
    padding: 20px 0px;
    color: rgb(105, 105, 105);
}
.alert{
    background: white;
    padding: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 20px;
}
.bg-black{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100dvh;
    transform: translate(-50%, -50%);
    z-index: 1;
    backdrop-filter: blur(5px);
}
@media screen and (max-width: 800px){
    .main-location{
        height: max-content;
    }
    .contact-cards{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .loc-box{
        flex-direction: column;
        gap: 10px;
    }
    .hover-wrap{
        flex-direction: column;
    }
    .vision-container h2{
        font-size: 24px;
    }
    .vision-container p{
        padding: 0px 30px;
        font-size: 18px;
    }
    .vision-container{
        padding: 20px 0px;
    }
    .contact-container img {
        width: 47px;
        margin-top: 11px;
    }
    .cards-cont h3{
        font-size: 15px;
    }
    .cards-cont{
        padding-right: 5px;
        height: 55px;
    }
    .cards-cont p{
        line-height: 20px;
    }
}
@media screen and (max-width: 550px){
    .hero-text h1{
        font-size: 33px;
    }
    .hero-text h3{
        font-size: 25px;
        margin-top: 5px;
    }
    .sof-text h2{
        font-size: 22px;
    }
    .sof-text h2::after{
        content: '';
        height: 2px;
        background-color: white;
        width: 20%;
        position: absolute;
        bottom: 15px;
        left: 175px;
    }
    .home-hero{
        margin-top: 150px;
    }
    .main-location h2{
        font-size: 22px;
    }
    .main-location h2::after{
        left: 160px;
        width: 35%;
        bottom: 11px;
    }
    .hover-open-title{
        padding: 15px 20px;
    }
    .hover-open p{
        padding: 10px 20px;
    }
    .vision-container p{
        padding: 0px 20px;
        font-size: 18px;
    }
}