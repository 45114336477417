.header-wrapper{
    width: 100%;
    /* background: linear-gradient(90deg, rgb(7,21,36) 0%, rgb(15,44,65) 45%, rgb(7,21,36) 100%); */
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-header{
    width: 80%;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-header img{
    width: 250px;
}
.top-header #open-btn{
    display: none;
}
.top-header ul{
    display: flex;
    gap: 50px;
    align-items: center;
}
ul li{
    list-style: none;
    font-size: 18px;
    position: relative;
    margin: 0px 10px;
}
ul img{
    display: none;
}
ul li a{
    text-decoration: none;
    color: white;
}
ul li::after{
    content: "";
    position: absolute;
    background-color: rgb(255, 255, 255);
    height: 2px;
    width: 0;
    left: 0;
    bottom: -7px;
    transition: 0.3s;
}
ul li:hover::after{
    width: 100%;
}

@media screen and (max-width: 1025px){
    .top-header ul{
        padding: 80px 60px 0px 0px;
        display: flex;
        gap: 50px;
        flex-direction: column;
        height: 100dvh;
        background: black;
        color: white;
        position:fixed;
        width: 150px;
        top: 0px;
        align-items: flex-end;
        right: -212px;
    }
    ul img{
        display: block;
        position: absolute;
        top: 15px;
        right: 160px;
        width: 30px !important;
        cursor: pointer;
    }
    .top-header #open-btn{
        display: block;
        width: 50px;
    }
}
@media screen and (max-width: 800px){
  
}
@media screen and (max-width: 550px){
    .top-header img{
        width: 180px;
    }
    .top-header #open-btn{
        width: 40px;
    }
}